import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
import { R, c, u } from "./convert-value-Dm8azUUv.js";
const LazyFormRadioGroup = lazy(
  () => import("./form-radio-group-D4GrH9bg.js").then((module) => ({
    default: module.FormRadioGroup
  }))
);
function FormRadioGroup({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormRadioGroup, { ...props });
}
const useFormRadioGroupContent = () => {
  return useMemo(
    () => ({
      formRadioGroup: {
        component: FormRadioGroup
      }
    }),
    []
  );
};
export {
  LazyFormRadioGroup as FormRadioGroup,
  R as RadioGroupContextProvider,
  c as convertValue,
  useFormRadioGroupContent,
  u as useRadioGroup
};
